import i18n from '@/i18n';

export default {
  titulo: i18n.t('menu.sistema.modulos'),
  modulos: [
    {
      id: 'operacional',
      value: i18n.t('menu.sistema.operacional'),
    }, {
      id: 'movimentacao',
      value: i18n.t('menu.cadastro.movimentacao'),
    },
    {
      id: 'orcamentos',
      value: i18n.t('menu.cadastro.orcamentosContratos'),
    },
    {
      id: 'calculos',
      value: i18n.t('menu.cadastro.calculos'),
    },

    {
      id: 'relatorios',
      value: i18n.t('menu.cadastro.relatorios'),
    },
    {
      id: 'configuracoes',
      value: i18n.t('menu.cadastro.configuracoes'),
    },
  ],
  children: {
    operacional: [
      {
        id: 'area-acreditacao',
        to: 'area-acreditacao',
        funcionalidade:"AreaAcreditacao",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.area_acreditacao'),
      },
      {
        id: 'categoria-item',
        to: 'categoria-item',
        funcionalidade: "CategoriaItem",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.categoria_item'),
      },
      {
        id: 'cargo-funcao',
        to: 'cargo-funcao',
        funcionalidade: "CargoFuncao",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.cargo_funcao'),
      },
      {
        id: 'checklist',
        to: 'checklist',
        funcionalidade: "Checklist",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.checklist'),
      },
      {
        id: 'classificacao-fiscal',
        to: 'classificacao-fiscal',
        funcionalidade: "ClassificacaoFiscal",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.classificacao_fiscal'),
      },
      {
        id: 'classificacao-instrumento',
        to: 'classificacao-instrumento',
        funcionalidade: "ClassificacaoInstrumento",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.classificacao_instrumento'),
      },
      {
        id: 'colaborador',
        to: 'colaborador',
        funcionalidade: "Colaborador",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.colaboradores'),
      },
      {
        id: 'condicao-pagamento',
        to: 'condicao-pagamento',
        funcionalidade: "CondicaoPagamento",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.condicoes_pagamento'),
      },
      {
        id: 'conversor-unidade',
        to: 'conversor-unidade',
        funcionalidade: "UnidadeMedidaConversao",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.conversor_unidade'),
      },
      {
        id: 'criterio-aceitacao',
        to: 'criterio-aceitacao',
        funcionalidade: "CriterioAceitacao",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.criterio_aceitacao'),
      },
      {
        id: 'definicao-equipamento-medicao',
        to: 'definicao-equipamento-medicao',
        funcionalidade: "DefinicaoEquipamentoMedicao",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.definicao_equipamento_medicao'),
      },
      {
        id: 'duvida-solicitacao-participante',
        to: 'duvida-solicitacao-participante',
        funcionalidade: "DuvidaSolicitacaoParticipante",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.duvida_solicitacao_participante'),
      },
      {
        id: 'emabalagem',
        to: 'embalagem',
        funcionalidade: "Embalagem",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.embalagem'),
      },
      {
        id: 'feriado',
        to: 'feriado',
        funcionalidade: "Feriado",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.feriado'),
      },
      {
        id: 'grandeza-fisica',
        to: 'grandeza-fisica',
        funcionalidade: "GrandezaFisica",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.grandeza_fisica'),
      },
      {
        id: 'instrumento',
        to: 'instrumento',
        funcionalidade: "Instrumento",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.instrumento'),
      },
      {
        id: 'item',
        to: 'item',
        funcionalidade: "Item",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.item'),
      },
      {
        id: 'local-estocagem',
        to: 'local-estocagem',
        funcionalidade: "LocalEstocagem",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.local_estocagem'),
      },
      {
        id: 'local-execucao',
        to: 'local-execucao',
        funcionalidade: "LocalExecucao",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.local_execucao'),
      },
      {
        id: 'marca',
        to: 'marca',
        funcionalidade: "Marca",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.marca'),
      },
      {
        id: 'modelo-instrumento',
        to: 'modelo-instrumento',
        funcionalidade: "ModeloInstrumento",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.modelo_instrumento'),
      },

      {
        id: 'ocorrencia-padrao',
        to: 'ocorrencia-padrao',
        funcionalidade: "OcorrenciaPadrao",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.ocorrencias_padroes'),
      },
      {
        id: 'participantes',
        to: 'participantes',
        funcionalidade: "Participante",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.participante'),
      },
      {
        id: 'prioridade',
        to: 'prioridade',
        funcionalidade: "Prioridade",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.prioridade'),
      },
      {
        id: 'procedimentos',
        to: 'procedimentos',
        funcionalidade: "Procedimento",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.procedimento'),
      },
      {
        id: 'registro-informativo',
        to: 'registro-informativo',
        funcionalidade: "RegistroInformativo",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.registro_informativo'),
      },
      {
        id: 'registro-manutencao-troca-pecas',
        to: 'registro-manutencao-troca-pecas',
        funcionalidade: "InstrumentoRegistroManutencaoTrocaPeca",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.registros_manutencao_troca_pecas'),
      },
      {
        id: 'requisitos',
        to: 'requisitos',
        funcionalidade: "Requisitos",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.requisitos'),
      },
      {
        id: 'servico-calibracao',
        to: 'servico-calibracao',
        funcionalidade: "ServicoCalibracao",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.servico_calibracao'),
      },
      {
        id: 'servico-manutencao',
        to: 'servico-manutencao',
        funcionalidade: "ServicoManutencao",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.servico_manutencao'),
      },
      {
        id: 'setor',
        to: 'setor',
        funcionalidade: "Setor",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.setor'),
      },
      {
        id: 'solicitacao-cadastro',
        to: 'solicitacao-cadastro',
        funcionalidade: "SolicitacaoCadastro",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.solicitacao_cadastro'),
      },
      {
        id: 'tipo-calibracao',
        to: 'tipo-calibracao',
        funcionalidade: "TipoCalibracao",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.tipo_calibracao'),
      },
      {
        id: 'tipo-comentario',
        to: 'tipo-comentario',
        funcionalidade: "TipoComentarioOrcamento",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.tipo_comentario'),
      },
      {
        id: 'tipo-documento',
        to: 'tipo-documento',
        funcionalidade: "TipoDocumento",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.tipo_documento'),
      },
      {
        id: 'tipo-envio',
        to: 'tipo-envio',
        funcionalidade: "TipoEnvio",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.tipo_envio'),
      },
      {
        id: 'tipo-instrumento',
        to: 'tipo-instrumento',
        funcionalidade: "TipoInstrumento",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.tipo_instrumento'),
      },
      {
        id: 'tipo-visita',
        to: 'tipo-visita',
        funcionalidade: "TipoVisita",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.tipo_visita'),
      },
      {
        id: 'tipo-item',
        to: 'tipo-item',
        funcionalidade: "TipoItem",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.tipo_item'),
      },
      {
        id: 'tipo-ocorrencia',
        to: 'tipo-ocorrencia',
        funcionalidade: "TipoOcorrencia",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.tipo_ocorrencia'),
      },
      {
        id: 'tipo-setores',
        to: 'tipo-setores',
        funcionalidade: "TipoSetores",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.tipo_setor'),
      },
      {
        id: 'unidade-medida',
        to: 'unidade-medida',
        funcionalidade: "UnidadeMedida",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.unidade_medida'),
      },
      {
        id: 'veiculo',
        to: 'veiculo',
        funcionalidade: "Veiculo",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.veiculo'),
      },
    ],
    orcamentos: [
      {
        id: 'mensagem-orcamento',
        to: 'mensagem-orcamento',
        funcionalidade: "Mensagem",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.mensagem_orcamento'),
      },
      {
        id: 'orcamentos',
        to: 'orcamentos',
        funcionalidade: "Orcamento",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.orcamentos'),
      },
      {
        id: 'clausula-contratual',
        to: 'clausula-contratual',
        funcionalidade: "ClausulaContratual",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.clausulas_contratuais'),
      },
      {
        id: 'contrato',
        to: 'contrato',
        funcionalidade: "Contrato",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.contratos'),
      },
    ],
    calculos: [
      {
        id: 'acreditacao',
        to: 'acreditacao',
        funcionalidade: "Acreditacao",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.acreditacao'),
      },
      {
        id: 'certificado',
        to: 'certificado',
        funcionalidade: "Certificado",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.certificado'),
      },
      {
        id: 'certificado-interno',
        to: 'certificado-interno',
        funcionalidade: "CertificadoInterno",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.certificado_interno'),
      },
      {
        id: 'certificado-terceiro',
        to: 'certificado-terceiro',
        funcionalidade: "CertificadoTerceiro",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.certificado_terceiro'),
      },
      {
        id: 'escopo-servico',
        to: 'escopo-servico',
        funcionalidade: "Escopo",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.escopos_servicos'),
      },
      {
        id: 'formula',
        to: 'formula',
        funcionalidade: "Formula",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.formula'),
      },
      {
        id: 'incertezas',
        to: 'incertezas',
        funcionalidade: "Incerteza",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.incertezas'),
      },
      {
        id: 'procedimentos-eletronicos',
        to: 'procedimentos-eletronicos',
        funcionalidade: "ProcedimentoEletronico",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.procedimento_eletronico'),
      },
      {
        id: 'verificacao-intermediaria',
        to: 'verificacao-intermediaria',
        funcionalidade: "VerificacaoIntermediaria",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.verificacoes_intermediarias'),
      },
    ],
    movimentacao: [
      {
        id: 'agendamento',
        to: 'agendamento',
        funcionalidade:"Agendamento",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.agendamento')
      },
      {
        id: 'operacao',
        to: 'operacao',
        funcionalidade:'Operacao',
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.operacoes'),
      },
      {
        id: 'ordem-servico',
        to: 'ordem-servico',
        funcionalidade:'OrdemServico',
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.ordem_servico'),
      },
      {
        id: 'envio-material',
        to: 'envio-material',
        funcionalidade: "EnvioMaterial",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.envio_material')
      },
      {
        id: 'motivo-cancelamento-roteiro',
        to: 'motivo-cancelamento-roteiro',
        funcionalidade: "RoteiroMotivoCancelamento",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.roteiro_motivo_cancelamento'),
      }
      ,
      {
        id: 'roteiro-envio-material',
        to: 'roteiro-envio-material',
        funcionalidade: "RoteiroEnvioMaterial",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.roteiro_envio_material')
      },
      {
        id: 'kanban',
        to: 'kanban',
        funcionalidade:"Kanban",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.kanban_titulo')
      },

      {
        id: 'programacoes',
        to: 'programacoes',
        funcionalidade:"Programacoes",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.kanban_programacoes')
      },
      {
        id: 'em-execucao',
        to: 'em-execucao',
        funcionalidade:"EmExecucao",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.em_execucao')
      }
    ],
    relatorios: [
      {
        id: 'categoria-relatorio',
        to: 'categoria-relatorio',
        funcionalidade: "RelatorioCategoria",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.categorias_relatorios'),
      },
      {
        id: 'modelo-relatorio',
        to: 'modelo-relatorio',
        funcionalidade:"Relatorio",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.relatorios'),
      },
      {
        id: 'endpoints',
        to: 'endpoints',
        funcionalidade:"Endpoint",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.endpoints'),
      },
    ],
    configuracoes: [
      {
        id: 'campo-adicional',
        to: 'campo-adicional',
        funcionalidade: "CampoAdicional",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.campo_adicional'),
      },
      {
        id: 'template-email',
        to: 'modelo-email',
        funcionalidade: "EmailTemplate",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.modelo_email'),
      },
      {
        id: 'papel',
        to: 'papel',
        funcionalidade:'Papel',
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.papel'),
      },
      {
        id: 'parametros',
        to: 'parametros',
        funcionalidade: "Parametro",
        funcionalidadeAcao:"Editar",
        titulo: i18n.t('menu.cadastro.parametro'),
      },
      {
        id: 'usuario',
        to: 'usuario',
        funcionalidade: "Usuarios",
        funcionalidadeAcao:"Listar",
        titulo: i18n.t('menu.cadastro.usuario'),
      },
    ],
  },
};
