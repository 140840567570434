<template>
  <v-app class="dashboard">
    <barra-superior v-if="inverterToggleMenuSuperior" />
    <v-main style="background-color: rgb(240, 240, 240) !important">
      <!-- não mudar essa tag, manter as classes w-97 w-lg-85 mb-6 w500 white-->
      <div
        class="w-98 mb-6 w500 white"
        style="margin: auto; margin-top: 15px; box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; border-radius: 10px; padding: 10px;"
      >
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import BarraSuperior from './components/BarraSuperior.vue';
import { EventTypes, useEmitterBus } from '@plugins/emitter-bus.js';
export default {
  name: 'FullLayout',
  components: { BarraSuperior },
  data() {
    return {
      toggleMenuSuperior: false,
      emitterGlobal: useEmitterBus(),
    };
  },
  computed: {
    inverterToggleMenuSuperior() {
      return !this.toggleMenuSuperior;
    }
  },
  mounted() {
    this.emitterGlobal.listen(EventTypes.ToggleMenuSuperior, () => {
      this.toggleMenuSuperior = !this.toggleMenuSuperior;
    });
  },
};
</script>
