import Emitter from "tiny-emitter";
const emitter = new Emitter();

export const EventTypes = {
    QRCodeEnvioMaterial: "QRCodeEnvioMaterial",
    FiltroGrid: "FiltroGrid",
    ToggleMenuSuperior: "ToggleMenuSuperior",
}

const validateEvent = (EVENT_NAME) => {
    if(EVENT_NAME === undefined || EVENT_NAME === null || EVENT_NAME === "") return false;
    if(!Object.keys(EventTypes).includes(EVENT_NAME)){
        console.error(`The Event ${EVENT_NAME} is not defined in EventTypes`);
        return false;
    }
    return true;
}

export function useEmitterBus() {
  function emit(EVENT_NAME, dados) {
    if(!validateEvent(EVENT_NAME)) return;
    emitter.emit(EVENT_NAME, dados);
  }

  function listen(EVENT_NAME, fnCallback) {
    if(!validateEvent(EVENT_NAME)) return;
    emitter.on(EVENT_NAME, fnCallback);
  }

  return { emit, listen };
}
