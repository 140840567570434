<template>
  <div class="d-flex align-center ml-1">
    <v-menu
      v-model="menuAberto"
      offset-y
      class="d-flex justify-center"
      allow-overflow
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          text
          class="botao-barra-superior mr-0"
          style="color: white"
          v-on="on"
        >
          <v-icon :size="20"> $menu </v-icon>
        </v-btn>
      </template>
      <input-text
        v-if="$vuetify.breakpoint.name == 'xs'"
        v-model="textoFiltro"
        class="mt-2 px-2"
        sem-label
        placeholder="Buscar"
        @input="filtrarMenu"
        @esc="limparFiltro"
      >
        <template #append>
          <v-icon> $search </v-icon>
        </template>
      </input-text>
      <div v-for="(modulo, index) in menuItens.modulos" :key="index">
        <!-- Quando tem mais de uma coluna de telas -->
        <v-menu
          v-if="!rotasFiltradas.length && verificarSeDeveSerExibido(modulo)"
          offset-x
          :auto="$vuetify.breakpoint.name == 'xs'"
          :open-on-hover="$vuetify.breakpoint.name != 'xs'"
          class="d-flex align-center"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              class="py-2 px-4 item-barra-superior item-list-barra-superior"
              v-bind="attrs"
              v-on="on"
            >
              {{ modulo.value }}
            </div>
          </template>
          <v-list v-if="$vuetify.breakpoint.name != 'xs'" class="d-flex">
            <div
              v-for="(
                itemGrupo, indexItem
              ) in filtrarItensAgrupadosQueDevemSerExibidos(
                moduloItens[modulo.id]
              )"
              :key="indexItem"
              style="width: 240px; overflow: hidden"
              :class="{ 'itens-menu-separador': indexItem }"
            >
              <ItensMenu :itens="itemGrupo" @navegarRota="navegarRota" />
            </div>
          </v-list>
          <!-- Que possuem somente uma coluna -->
          <v-list
            v-if="$vuetify.breakpoint.name == 'xs'"
            class="d-flex flex-column"
          >
            <ItensMenu
              :itens="menuItens.children[modulo.id]"
              @navegarRota="navegarRota"
            />
          </v-list>
        </v-menu>
      </div>
      <v-list v-if="rotasFiltradas.length" class="d-flex flex-column">
        <ItensMenu :itens="rotasFiltradas" @navegarRota="navegarRota" />
      </v-list>
    </v-menu>
    <input-text
      v-if="$vuetify.breakpoint.name != 'xs'"
      v-model="textoFiltro"
      sem-label
      class="ml-1"
      dark
      placeholder="Buscar"
      @input="filtrarMenu"
      @esc="limparFiltro"
    >
      <template #append>
        <v-icon> $search </v-icon>
      </template>
    </input-text>
  </div>
</template>
<script>
import ItensMenu from './ItensMenu.vue';
import helpers from '@common/utils/helpers';

export default {
  components: { ItensMenu },
  props: {
    menuItens: { type: Object, default: () => new Object() },
  },
  data: () => ({
    moduloItens: {},
    dividirMenuEm: 14,
    menuAberto: false,
    rotasFiltradas: [],
    textoFiltro: '',
  }),
  created() {
    this.dividirMenu();
  },
  methods: {
    filtrarMenu() {
      this.rotasFiltradas = [];
      if (this.textoFiltro) {
        this.menuAberto = true;
        this.menuItens.modulos.forEach((modulo) => {
          this.menuItens.children[modulo.id].forEach((rota) => {
            if (
              this.montarStringBuscaMenu(rota.titulo).includes(
                this.textoFiltro.toLowerCase()
              )
            )
              this.rotasFiltradas.push(rota);
          });
        });
      }
      if (this.textoFiltro && !this.rotasFiltradas.length) {
        this.rotasFiltradas.push({ titulo: 'Nada encontrado' });
      }
    },
    verificarSeDeveSerExibido(modulo) {
      return (
        this.menuItens.children[modulo.id].filter((item) =>
          this.buscarPermissao(item.funcionalidade)
        ).length > 0
      );
    },
    filtrarItensAgrupadosQueDevemSerExibidos(itemsGroups) {
      return itemsGroups.filter(
        (group) =>
          group.filter((item) => this.buscarPermissao(item.funcionalidade))
            .length > 0
      );
    },
    limparFiltro() {
      this.rotasFiltradas = [];
      this.menuAberto = false;
    },
    dividirMenu() {
      this.menuItens.modulos.forEach((modulo) => {
        this.moduloItens[modulo.id] = [];
        this.menuItens.children[modulo.id].forEach((item, index) => {
          const posicao = this.calcularPosicaoArray(index + 1);
          if (!this.moduloItens[modulo.id][posicao])
            this.moduloItens[modulo.id][posicao] = [];
          this.moduloItens[modulo.id][posicao].push(item);
        });
      });
    },
    buscarPermissao(funcionalidade) {
      return helpers.buscarStatePermissoes(this, funcionalidade, 'Listar');
    },
    calcularPosicaoArray(value) {
      if (value % this.dividirMenuEm == 0)
        return value / this.dividirMenuEm - 1;
      return Math.floor(value / this.dividirMenuEm);
    },
    obterQuantidadeItens(moduloId) {
      return this.menuItens.children[moduloId].length;
    },
    calcularTamanhoDropDown(modulo) {
      const valorCalculado = Math.ceil(
        this.obterQuantidadeItens(modulo) / this.dividirMenuEm
      );
      if (valorCalculado == 1) return 110;
      return valorCalculado * 150;
    },
    montarStringBuscaMenu(texto) {
      const retorno = texto.concat(
        helpers.removerAcentuacao(texto),
        helpers.removerPluralString(texto),
        helpers.removerPluralString(texto).replace(' de ', ' '),
        texto.replace(' de ', ' ')
      );
      return retorno.toLowerCase();
    },
    navegarRota() {
      setTimeout(() => {
        this.menuAberto = false;
        this.textoFiltro = '';
      }, 100);
    },
  },
};
</script>
<style>
.transition-menu {
  transform: rotate(180deg);
}

.itens-menu-separador {
  border-left: 1px solid #ece4e4;
}

.botao-barra-superior {
  font-size: 0.82rem !important;
  font-weight: 500 !important;
  text-transform: capitalize;
  padding: 0 10px !important;
  border-radius: 3px !important;
  font-family: 'Work Sans', sans-serif !important;
  letter-spacing: normal;
}

.menu-cabecalho-pagina {
  overflow: auto !important;
}
</style>
