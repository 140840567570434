<template>
  <div>
    <div class="d-flex align-center">
      <dropdown-padrao
        text
        color="secondary"
        class="mr-2"
      >
        <template #botao>
          <v-badge
            v-if="numeroNotificacoes"
            :content="numeroNotificacoes"
          >
            <v-icon
              size="20"
              color="#929396"
            >
              $bellOutline
            </v-icon>
          </v-badge>
          <v-icon
            v-if="!numeroNotificacoes"
            size="20"
            color="white"
          >
            $bell
          </v-icon>
        </template>
        <div>
          <v-list>
            <v-list-item>
              <v-list-item-title class="marcar-mensagens-lidas">
                <strong
                  @click="marcarTodasNotificacoesComoLidas"
                >Marcar Todas como Lidas</strong>
                <v-divider />
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-for="(item, index) in notificacoes"
              :key="index"
              :value="index"
              :style="
                item.flagVisualizada
                  ? { background: 'white !important' }
                  : { background: '#e4eaef', color: 'black' }
              "
            >
              <v-list-item-title
                @click.stop.prevent="marcarNotificacaoComoLida(item.id)"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div
                      class="'pl-0'"
                      v-on="item.notificacao ? on : null"
                    >
                      <div
                        style="font-size: 13px"
                        class="texto-notificacao"
                      >
                        <v-icon size="24">
                          $comment
                        </v-icon>
                        <span>{{ item.notificacao }}</span>
                      </div>
                    </div>
                  </template>
                  <span>{{ item.notificacao }}</span>
                </v-tooltip>
                <v-divider />
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
      </dropdown-padrao>
      <div
        v-if="$vuetify.breakpoint.width >= 1470"
        class="borda-avatar"
      >
        <v-avatar
          color="#ccc"
          class="black--text"
          size="38"
        >
          {{ iniciaisUsuario }}
        </v-avatar>
      </div>
      <div
        v-if="$vuetify.breakpoint.width >= 1470"
        class="ml-2 mr-4 pr-2 borda-direita d-flex flex-column justify-space-between h-20"
      >
        <div class="white--text">
          <span style="font-weight: 600">
            {{ nomeUsuario }}
          </span>
          <span>({{ papelUsuario }})</span>
        </div>
        <input-select
          v-model="participanteLogado.id"
          class="input-select-participante"
          sem-label
          height="14"
          :clearable="false"
          :options="participantes"
          :outlined="false"
          flat
          solo
          estilo-customizado="font-size: 0.85rem; font-weight: 500"
          @change="alterarParticipante"
        />
      </div>
      <DadosUsuarioResponsivo
        v-if="$vuetify.breakpoint.width < 1470"
        :nome-usuario="nomeUsuario"
        :iniciais-usuario="iniciaisUsuario"
        :email-usuario="emailUsuario"
        :participantes="participantes"
        :participante-logado="participanteLogado"
        @alterarParticipante="alterarParticipante"
      />
      <botao-padrao
        class="botao-icone-quadrado mr-2"
        color="white"
        outlined
      >
        <v-icon
          size="20"
          color="white"
        >
          $cogOutline
        </v-icon>
      </botao-padrao>
      <botao-padrao
        class="botao-icone-quadrado"
        color="white"
        outlined
        @click="logout"
      >
        <v-icon
          size="24"
          color="white"
        >
          $logout
        </v-icon>
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import UsuarioService from '@common/services/cadastros/UsuarioService';
import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
} from '@microsoft/signalr';
const { VUE_APP_HUB } = process.env;
import NotificacaoService from '@common/services/cadastros/NotificacaoService';
import DadosUsuarioResponsivo from './DadosUsuarioResponsivo.vue';
import { EventTypes, useEmitterBus } from '@plugins/emitter-bus.js';

export default {
  components: { DadosUsuarioResponsivo },
  data: () => ({
    signalRConnection: null,
    usuarioId: null,
    notificacoes: [],
    numeroNotificacoes: 0,
    participanteLogado: {
      id: null,
    },
    emitterGlobal: useEmitterBus(),
  }),
  computed: {
    ...mapGetters('Autenticacao', ['usuario', 'participanteSelecionado']),
    iniciaisUsuario: function () {
      if (!this.usuario) return '';
      let nomeSplit = this.usuario.nome.toUpperCase().split(' ');
      if (nomeSplit.length == 1) return nomeSplit[0].substr(0, 2);
      return `${nomeSplit[0][0]}${nomeSplit[1][0]}`;
    },
    nomeUsuario: function () {
      if (!this.usuario) return '';
      let nomeSplit = this.usuario.nome.split(' ');
      if (nomeSplit.length == 1) return nomeSplit[0];
      return `${nomeSplit[0]} ${nomeSplit[nomeSplit.length - 1]}`;
    },
    emailUsuario: function () {
      if (!this.usuario) return '';
      return this.usuario.email;
    },
    papelUsuario: function () {
      if (!this.usuario) return '';
      return this.usuario.papel?.nome;
    },
    participantes: function () {
      if (!this.usuario) return [];
      return this.usuario.participantes;
    },
    widthTela() {
      return this.$vuetify.breakpoint.width;
    },
  },
  mounted() {
    this.configureSignalR();
    this.carregarNotificoes();
    this.usuarioId = localStorage.getItem('usuarioId');
    this.participanteLogado.id = this.participanteSelecionado;
  },
  methods: {
    ...mapActions(['clearStore']),
    logout() {
      this.clearStore();
      this.clearErrorStore();
      this.$store.dispatch('Autenticacao/limparAutenticacao');
      this.$router.push({ name: 'login' });
    },
    async configureSignalR() {
      if (!VUE_APP_HUB) return;
      this.signalRConnection = new HubConnectionBuilder()
        .withUrl(`${VUE_APP_HUB}/hub/notificacao-sistema`, {
          accessTokenFactory: () =>
            this.$store.getters['Autenticacao/token'].replace('Bearer ', ''),
          transport: HttpTransportType.WebSockets,
        })
        .configureLogging(LogLevel.Error)
        .build();

      this.signalRConnection.onclose(() => {
        this.start();
      });

      this.start();
    },
    start() {
        console.info('Tentando conectar ao SignalR')
        this.signalRConnection.start().then(() => {
          console.log("Conectado ao SignalR!")
          this.signalRConnection.on('NovaNotificacaoSistema', (action) => {
            this.carregarNotificoes(action);
          });
          this.signalRConnection.on('QrCodeEnvioMaterial', (id) => {
            this.emitterGlobal.emit(EventTypes.QRCodeEnvioMaterial, id);
          });
        }).catch(() => {
          setTimeout(() => {
            const state = this.signalRConnection.connection._connectionState;
            if(!state || state == 'Disconnected') {
              this.start()
            }
          }, 5000)
        })
    },
    carregarNotificoes() {
      let parametros = {
        page: 1,
        amountPerPage: 90,
        flagVisualizada: false,
        usuarioDestinoId: this.usuario?.id,
      };
      if(!this.usuario?.id) return;
      NotificacaoService.listar(parametros).then((res) => {
        this.notificacoes = res.data.items;
        this.numeroNotificacoes = this.notificacoes.length;
      });
    },
    marcarNotificacaoComoLida(id) {
      let params = {
        id,
      };
      NotificacaoService.marcarComoLida(id, params)
        .then(() => {
          this.notificacoes.forEach((notificacao) => {
            if (notificacao.id === id && !notificacao.flagVisualizada) {
              notificacao.flagVisualizada = true;
              if (this.numeroNotificacoes > 0) this.numeroNotificacoes--;
            }
          });
        })
        .catch(() => {
          return false;
        });
    },
    marcarTodasNotificacoesComoLidas() {
      let mensagensNaoMarcadas = 0;

      this.notificacoes.forEach((notificacao) => {
        let params = {
          id: notificacao.id,
        };
        NotificacaoService.marcarComoLida(notificacao.id, params)
          .then(() => {
            notificacao.flagVisualizada = true;
          })
          .catch(() => {
            mensagensNaoMarcadas++;
          });
      });

      this.numeroNotificacoes = mensagensNaoMarcadas;
    },
    alterarParticipante(val) {
      if (!val || val == this.participanteSelecionado) return;
      const mensagem = this.$t('geral.alertas.alterar_participante_logado');
      this.confirmar(mensagem.titulo, mensagem.mensagem)
        .then(() => {
          this.participanteLogado.id = val;
          this.$store.dispatch(
            'Autenticacao/alterarParticipanteSelecionado',
            val
          );
          this.$store.dispatch(
            'Layout/iniciarCarregamento',
            'Alterando Participante'
          );
          UsuarioService.alterarParticipante({ participanteLogadoId: val })
            .then((res) => {
              this.$store.dispatch('Autenticacao/limparAutenticacao');
              const encoded = JSON.parse(atob(res.data.token.split('.')[1]));
              localStorage.setItem(
                'participanteLogadoId',
                encoded.participante_logado_id
              );
              
              setTimeout(() => {
                localStorage.setItem('usuarioId', res.data.usuario?.id);
                this.usuarioId = res.data.usuario?.id;
                localStorage.setItem('usuarioNome', res.data.usuario?.nome);
                this.$store.dispatch('Autenticacao/autenticar', {
                  tokenData: res.data,
                  encodedData: encoded,
                });
                this.$router.push({ name: 'dashboard' });
              }, 300);
            })
            .finally(() => {
              setTimeout(() => {
                this.$store.dispatch('Layout/terminarCarregamento');
              }, 300);
            });
        })
        .catch(() => {
          this.participanteLogado.id = this.participanteSelecionado;
        });
    },
  },
};
</script>
<style lang="scss">
.borda-avatar {
  border: 2px solid #fff;
  border-radius: 50%;
  font-size: 0.9rem;
}

.input-select-participante {
  width: 200px;

  .v-input__slot {
    padding: 0px !important;
    background: rgb(53, 80, 123) !important;
  }

  .v-input__control {
    min-height: 0px !important;
  }

  .v-select__selection {
    color: #929396;
  }
}

.borda-direita {
  border-right: 1px solid #ece4e4;
  font-size: 0.85rem !important;
}

.texto-notificacao {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  span {
    display: inline-block;
    text-wrap: wrap;
  }
}
</style>
