<template>
  <div>
    <router-link
      v-for="item in itensFiltrados"
      :key="item.id"
      :to="{ name: item.to }"
    >
      <v-list-item
        class="item-list-barra-superior"
        @click="navegarRota"
      >
        <v-list-item-title
          class="item-barra-superior item-barra-superior-texto-cortado"
        >
          {{ item.titulo }}
        </v-list-item-title>
      </v-list-item>
    </router-link>
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';

export default {
  props: {
    itens: { type: Array, default: () => [] },
  },
  computed: {
    itensFiltrados() {
      return this.itens.filter((item) =>
        this.buscarPermissao(item)
      );
    },
  },
  methods: {
    navegarRota() {
      this.$emit('navegarRota');
    },
    buscarPermissao(item) {
      return helpers.buscarStatePermissoes(this, item.funcionalidade, item.funcionalidadeAcao);
    },
  },
};
</script>
<style>
.item-list-barra-superior {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}

.item-list-barra-superior a {
  display: flex !important;
}

.item-barra-superior {
  cursor: pointer;
  font-size: 0.82rem;
  text-decoration: none;
  color: #000;
  width: 100% !important;
}

.item-list-barra-superior:hover {
  background-color: #e0e0e0;
  cursor: pointer;
}

.item-barra-superior-texto-cortado {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 225px;
}
</style>
