<template>
  <div class="barra-superior">
    <v-app-bar
      flat
      color="rgb(53, 80, 123)"
      app
      class="h-10"
      style="height: unset !important;"
    >
      <div
        class="d-flex align-center"
      >
        <a href="/">
          <img
            class="mr-4"
            height="40px"
            src="../../../assets/img/logo-white.svg"
            alt="logo"
          >
        </a>
        <Menu-responsivo
          v-if="$vuetify.breakpoint.width < 1240"
          :menu-itens="menuItens"
        />
        <Menu v-if="$vuetify.breakpoint.width >= 1240" />
      </div>
      <v-spacer />
      <usuario />
    </v-app-bar>
  </div>
</template>
<script>
import Usuario from './Usuario';
import MenuItens from './MenuItens';
import MenuResponsivo from './MenuResponsivo.vue'
import Menu from './Menu.vue';

export default {
  components: {
    Usuario,
    MenuResponsivo,
    Menu,
  },
  data: () => ({
      menuItens: MenuItens,
    }),

};
</script>
<style lang="scss">
.barra-superior {
  .v-toolbar__content {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 0px 16px;
    height: 60px !important;
  }
}

.label-kel-cabecalho {
  font-size: 0.86rem !important;
  font-weight: 500;
  width: 100px;
  margin-left: 10px;
}

</style>

