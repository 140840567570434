<template>
  <div class="d-flex align-center ml-1">
    <div v-for="modulo in menuItens.modulos" :key="modulo.id">
      <v-menu
        v-if="verificarSeDeveSerExibido(modulo)"
        class="menu-cabecalho-pagina"
        transition="slide-x-transition"
        offset-y
        rounded
      >
        <template v-slot:activator="{ attrs, on, value }">
          <v-btn
            v-bind="attrs"
            text
            class="botao-barra-superior"
            style="color: white"
            v-on="on"
          >
            {{ modulo.value }}
            <v-icon :class="{ 'transition-menu': value }" :size="20">
              $downArrow
            </v-icon>
          </v-btn>
        </template>
        <v-list class="d-flex">
          <div
            v-for="(
              itemGrupo, index
            ) in filtrarItensAgrupadosQueDevemSerExibidos(
              moduloItens[modulo.id]
            )"
            :key="index"
            style="width: 240px"
            :class="{ 'itens-menu-separador': index }"
          >
            <ItensMenu
              :itens="mostraItemMenu(itemGrupo)"
              @navegarRota="navegarRota"
            />
          </div>
        </v-list>
      </v-menu>
    </div>

    <v-menu
      v-model="buscarMenu"
      rounded
      transition="slide-x-transition"
      offset-y
      allow-overflow
    >
      <template v-slot:activator="{ attrs }">
        <input-text
          v-model="textoFiltro"
          v-bind="attrs"
          sem-label
          class="ml-1"
          dark
          placeholder="Buscar"
          @input="filtrarMenu"
          @esc="limparFiltro"
          @enter="eventoEnter"
        >
          <template #append>
            <v-icon> $search </v-icon>
          </template>
        </input-text>
      </template>
      <v-list v-if="rotasFiltradas.length" class="d-flex flex-column">
        <ItensMenu :itens="rotasFiltradas" @navegarRota="navegarRota" />
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import MenuItens from './MenuItens';
import ItensMenu from './ItensMenu';
import helpers from '@common/utils/helpers';
import { mapGetters } from 'vuex';

export default {
  components: {
    ItensMenu,
  },
  data: () => ({
    menuItens: MenuItens,
    moduloItens: {},
    buscarMenu: true,
    dividirMenuEm: 12,
    textoFiltro: '',
    rotasFiltradas: [],
  }),
  created() {
    this.dividirMenu();
  },
  computed: {
    ...mapGetters('Autenticacao', ['participanteLogadoConta']),
  },
  methods: {
    mostraItemMenu(itemGrupo) {
      const idsPermitidos = ['kanban', 'programacoes', 'em-execucao'];

      const temKanban = itemGrupo.some((item) =>
        idsPermitidos.includes(item.id)
      );
      if (!temKanban) return itemGrupo;

      const apenasPermitidos = itemGrupo.filter((item) =>
        idsPermitidos.includes(item.id) ? this.participanteLogadoConta : true
      );

      return apenasPermitidos;
    },
    filtrarMenu() {
      this.rotasFiltradas = [];
      if (this.textoFiltro) {
        this.buscarMenu = true;
        this.menuItens.modulos.forEach((modulo) => {
          this.menuItens.children[modulo.id].forEach((rota) => {
            const splitTextoBusca = this.textoFiltro.toLowerCase().split(' ');

            if (
              splitTextoBusca.every((texto) =>
                this.montarStringBuscaMenu(rota.titulo).includes(texto)
              )
            )
              this.rotasFiltradas.push(rota);
          });
        });
      }
      if (this.textoFiltro && !this.rotasFiltradas.length) {
        this.rotasFiltradas.push({ titulo: 'Nada encontrado' });
      }
    },
    limparFiltro() {
      this.rotasFiltradas = [];
      this.menuAberto = false;
    },
    verificarSeDeveSerExibido(modulo) {
      return (
        this.menuItens.children[modulo.id].filter((item) =>
          this.buscarPermissao(item.funcionalidade)
        ).length > 0
      );
    },
    filtrarItensAgrupadosQueDevemSerExibidos(itemsGroups) {
      return itemsGroups.filter(
        (group) =>
          group.filter((item) => this.buscarPermissao(item.funcionalidade))
            .length > 0
      );
    },
    buscarPermissao(funcionalidade) {
      return helpers.buscarStatePermissoes(this, funcionalidade, 'Listar');
    },
    dividirMenu() {
      MenuItens.modulos.forEach((modulo) => {
        this.moduloItens[modulo.id] = [];
        MenuItens.children[modulo.id].forEach((item, index) => {
          const posicao = this.calcularPosicaoArray(index + 1);
          if (!this.moduloItens[modulo.id][posicao])
            this.moduloItens[modulo.id][posicao] = [];
          this.moduloItens[modulo.id][posicao].push(item);
        });
      });
    },
    calcularPosicaoArray(value) {
      if (value % this.dividirMenuEm == 0)
        return value / this.dividirMenuEm - 1;
      return Math.floor(value / this.dividirMenuEm);
    },
    obterQuantidadeItens(moduloId) {
      return this.menuItens.children[moduloId].length;
    },
    calcularTamanhoDropDown(modulo) {
      const valorCalculado = Math.ceil(
        this.obterQuantidadeItens(modulo) / this.dividirMenuEm
      );
      if (valorCalculado == 1) return 110;
      return valorCalculado * 150;
    },
    montarStringBuscaMenu(texto) {
      const retorno = texto.concat(
        helpers.removerAcentuacao(texto),
        helpers.removerPluralString(texto),
        helpers.removerPluralString(texto).replace(' de ', ' '),
        texto.replace(' de ', ' ')
      );
      return retorno.toLowerCase();
    },
    eventoEnter() {
      const primeiroItem = this.rotasFiltradas[0];
      if (!primeiroItem) return;

      this.$router.push({ name: primeiroItem.to });
      this.navegarRota();
    },
    navegarRota() {
      setTimeout(() => {
        this.buscarMenu = false;
        this.textoFiltro = '';
      }, 100);
    },
  },
};
</script>
