<template>
  <v-menu
    offset-y
    nudge-width="180"
    class="d-flex justify-center"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <botao-padrao
        class="botao-icone-quadrado mr-2"
        color="white"
        v-bind="attrs"
        outlined
        v-on="on"
      >
        <v-icon
          size="24"
          color="white"
        >
          $mdiAccountDetails
        </v-icon>
      </botao-padrao>
    </template>
    <div class="d-flex justify-center align-center py-2 px-2">
      <v-avatar
        color="#0D47A1"
        class="white--text ml-1"
        size="35"
      >
        {{ iniciaisUsuario }}
      </v-avatar>
      <div class="ml-4">
        <span
          style="
          font-size: 14px;
          font-weight: 600;
          line-height: 32px;
          color: #344054;
        "
        >
          {{ nomeUsuario }}
        </span>
        <span style="color: #667085; font-size: 14px">
          {{ emailUsuario }}
        </span>
      </div>
    </div>
    <input-select
      v-model="participanteLogado.id"
      class="input-select-participante-responsivo ml-2 mr-2 mb-2"
      sem-label
      height="14"
      :clearable="false"
      :options="participantes"
      :outlined="true"
      flat
      solo
      estilo-customizado="font-size: 0.85rem; font-weight: 500"
      @change="alterarParticipante"
    />
  </v-menu>
</template>
<script>
export default {
  props: {
    participanteLogado: {type: Object, default: new Object},
    nomeUsuario: {type: String, default: ''},
    iniciaisUsuario: {type: String, default: ''},
    emailUsuario: {type: String, default: ''},
    participantes: {type: Array, default:() => []},
  },
  methods:{
    alterarParticipante(id){
      this.$emit("alterarParticipante", id)
    }
  }
}
</script>
<style>
.input-select-participante-responsivo {
  width: 250px;
  .v-input__slot {
    padding: 10px !important;
  }
}
</style>
